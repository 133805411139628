import { get, post, postWithoutToken, remove, update } from "@/api/getApi2.0";
import {
  ENTITY_NAME_IN_URI,
  ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2
} from "@/api/config/api2.0";

export const getUsers = (axios, filters = null) =>
  get(
    {
      path: `${ENTITY_NAME_IN_URI.USERS.path}`
    },
    filters,
    axios
  );

export const getUser = (axios, id) =>
  get(
    {
      path: `${ENTITY_NAME_IN_URI.USERS.path}/${id}`
    },
    {},
    axios
  );

export const createUser = (axios, data) =>
  post(
    {
      path: `${ENTITY_NAME_IN_URI_V2.USERS.path}`
    },
    {},
    data,
    axios
  );

export const removeUser = (axios, id) =>
  remove(
    {
      path: `${ENTITY_NAME_IN_URI.USERS.path}/${id}`
    },
    {},
    axios
  );

export const updateUser = (axios, id, data) =>
  update(
    {
      path: `${ENTITY_NAME_IN_URI_V2.USERS.path}/${id}`
    },
    data,
    axios
  );

export const forgotPassword = (axios, data) =>
  postWithoutToken(
    {
      path: ENTITY_NAME_IN_URI.SESSION.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.SESSION.forgot_password
        }
      ]
    },
    {},
    data,
    axios
  );

export const userForgotPassword = (axios, id, data) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.USERS.path,
      ressources: [
        {
          name: id,
          value: ENTITY_NAME_IN_URI.USERS.FORGOT_PASSWORD
        }
      ]
    },
    data,
    axios
  );

export const welcomeUser = (axios, id) =>
  post(
    {
      path: `${ENTITY_NAME_IN_URI_V2.EMAIL.welcome}/${id}`
    },
    {},
    {},
    axios
  );
