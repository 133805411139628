import i18n from "@/common/plugins/vue-i18n";
import { ROLES } from "@/common/config/acl/roles";

export const DROPDOWN = [
  {
    icon: "pencil",
    label: "Edit",
    color: "info",
    action: "edit",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  {
    icon: "lock",
    label: "Lock",
    action: "blocked",
    condition: "true",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  {
    icon: "lock-open",
    label: "Unlock",
    color: "success",
    action: "unblocked",
    condition: "false",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  {
    icon: "lock-reset",
    label: "Reset password",
    action: "reset",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  {
    icon: "send",
    label: "Welcome email",
    action: "welcome",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  {
    icon: "delete",
    label: "Delete",
    color: "error",
    action: "delete",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  }
];

export const headers = [
  {
    label: i18n.t("USERS.DATATABLE.EMAIL"),
    key: "email",
    sortable: true
  },
  {
    label: i18n.t("USERS.DATATABLE.FIRSTNAME"),
    key: "firstName",
    sortable: true
  },
  {
    label: i18n.t("USERS.DATATABLE.LASTNAME"),
    key: "lastName",
    sortable: true
  },
  {
    label: i18n.t("USERS.DATATABLE.ROLES"),
    key: "roles",
    sortable: true
  },
  {
    label: i18n.t("USERS.DATATABLE.SCOPE"),
    key: "scope",
    thStyle: { width: "5%" }
  },
  {
    label: i18n.t("USERS.DATATABLE.STATUS"),
    key: "status",
    thStyle: { width: "15%" }
  },
  {
    label: i18n.t("USERS.DATATABLE.ACTIONS"),
    key: "actions",
    thStyle: { width: "5%" }
  }
];
