<template>
  <div>
    <label for="tags-with-dropdown" class="font-weight-bold"
      >{{ label }} <span v-if="isRequired" class="text-danger">*</span>
      <HelperTooltip
        v-if="textTootlip"
        icon="mdi-help-circle"
        :text="textTootlip"
      />
    </label>

    <b-form-group>
      <b-form-tags
        id="tags-with-dropdown"
        v-model="value"
        no-outer-focus
        class="mb-2"
        :state="getState"
        :disabled="disabled"
      >
        <template v-slot="{ tags, addTag, removeTag }">
          <div class="row">
            <div class="col-11">
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-2 p-0"
              >
                <li
                  v-for="(tag, index) in value"
                  :key="`tag_${index}`"
                  class="list-inline-item mb-1"
                >
                  <b-form-tag
                    @remove="onRemoveTag({ removeTag, tag, tags })"
                    :title="tag"
                    :variant="variant"
                    >{{ toStringName(tag) }}</b-form-tag
                  >
                </li>
              </ul>
            </div>

            <div class="col-1 px-0 text-center">
              <v-btn
                v-if="clearSelectionVisible"
                icon
                small
                color="orange darken-2"
                title="Clear list"
                :disabled="tagsObject.length === 0"
                v-on:click="clearSelection({ removeTag, tags })"
              >
                <v-icon>mdi-playlist-remove</v-icon>
              </v-btn>
            </div>
          </div>

          <b-dropdown
            block
            :disabled="disabled"
            menu-class="w-100"
            class="form-control--grey"
          >
            <template #button-content>
              <v-icon>{{ buttonIcon }}</v-icon> {{ buttonText }}
            </template>

            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                :label="$tc('CAMPAIGN_TARGETING.FORM.SEARCH')"
                label-for="tag-search-input"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </b-dropdown-form>

            <b-dropdown-divider></b-dropdown-divider>

            <ul class="list-group list-group-flush pl-0">
              <li
                v-for="(option, index) in availableOptions"
                :key="`option_${index}`"
                @click="onOptionClick({ addTag, tags, option })"
                class="list-group-item list-group-item-action"
                style="cursor: pointer;"
              >
                {{ option[fieldName] + (option.parentId ? " (Disabled)" : "") }}
              </li>
            </ul>

            <div v-if="isLoading" class="d-flex w-100 justify-content-center">
              <b-spinner label="Spinning"></b-spinner>
            </div>

            <div
              v-if="isVisibleLoadMore"
              @click="loadMore"
              class="container-load-more d-flex w-100"
            >
              <span class="list-group-item list-group-item-action text-center"
                >Load more</span
              >
            </div>
          </b-dropdown>
        </template>
      </b-form-tags>

      <b-form-invalid-feedback id="tags-with-dropdown">
        {{ errorMessage }}
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<style></style>

<script>
import HelperTooltip from "@/components/HelperTooltip";

const findCommonElements = (arrayToSet, arrayOfObject) => {
  const setArray = new Set(arrayToSet);
  return arrayOfObject.filter(item => setArray.has(item.id));
};

const findCommon = (ref, arrayA, arrayB) => {
  const commonA = findCommonElements(ref, arrayA);
  const commonB = findCommonElements(ref, arrayB);

  const combinedCommon = [...commonA, ...commonB];

  return Array.from(
    new Map(combinedCommon.map(item => [item.id, item])).values()
  );
};

export default {
  name: "TagSelect",

  props: {
    listOptions: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      required: false
    },
    buttonIcon: {
      type: String,
      required: false
    },
    fieldName: {
      type: String,
      required: true
    },
    state: {
      type: Boolean,
      required: false
    },
    errorMessage: {
      type: String,
      required: false
    },
    textTootlip: {
      type: String,
      required: false
    },
    preSelectedElement: {
      type: Array,
      required: false
    },
    variant: {
      type: String,
      required: false
    },
    isPaginate: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    fieldsPreSelected: {
      type: String,
      require: false
    },
    currentPage: {
      type: Number,
      required: false
    },
    lastPage: {
      type: Number,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    clearSelectionVisible: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    HelperTooltip
  },

  data() {
    return {
      value: [],
      search: "",
      tagsObject: []
    };
  },

  computed: {
    criteria() {
      return this.search.trim().toLowerCase();
    },

    availableOptions() {
      const criteria = this.criteria;

      const idTagsSelected = [];

      this.tagsObject.forEach(element => {
        idTagsSelected.push(element.id);
      });

      const options = this.listOptions.filter(
        opt => idTagsSelected.indexOf(opt["id"]) === -1 && opt["id"] !== "*"
      );

      if (criteria) {
        return options.filter(
          opt => opt[this.fieldName].toLowerCase().indexOf(criteria) > -1
        );
      }

      return options;
    },

    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no element matching your search";
      }
      return "";
    },

    getState() {
      if (!this.state && !this.errorMessage) {
        return null;
      }

      return this.state;
    },

    isVisibleLoadMore() {
      return (
        this.isPaginate && !this.isLoading && this.currentPage < this.lastPage
      );
    }
  },

  methods: {
    toStringName(tagId) {
      const tag = this.tagsObject.find(item => item.id === tagId);

      if (tag) {
        return tag.name + (tag.parentId ? " (Disabled)" : "");
      }

      return "undefined";
    },

    onOptionClick({ addTag, tags, option }) {
      const tag = option["id"];
      addTag(tag);

      this.tagsObject = findCommon(
        [...tags, tag],
        this.listOptions,
        this.preSelectedElement
      );
      this.change();
      this.click(option);
    },

    onRemoveTag({ removeTag, tag, tags }) {
      const lastTags = tags.filter(item => item !== tag);

      removeTag(tag);

      this.tagsObject = findCommon(
        lastTags,
        this.listOptions,
        this.preSelectedElement
      );
      this.change();
    },

    clearSelection({ removeTag, tags }) {
      tags.forEach(tag => {
        removeTag(tag);
      });

      this.tagsObject = [];
      this.change();
      this.$emit("clear");
    },

    change() {
      this.$emit("change", this.tagsObject);
    },

    click(option) {
      this.$emit("click", option);
    },

    loadMore() {
      this.$emit("changePage", this.currentPage);
    }
  },

  watch: {
    preSelectedElement: {
      immediate: true,
      handler(elements) {
        Array.isArray(elements) &&
          elements.forEach(element => {
            if (!this.value.includes(element.id)) {
              this.value.push(element.id);
              this.tagsObject.push(element);
              this.change();
            }
          });
      }
    }
  }
};
</script>

<style>
.dropdown-menu {
  max-height: 250px;
  overflow: scroll;
}

.container-load-more:hover {
  background: #e2e5ee;
  cursor: pointer;
}

.load-more {
  cursor: pointer;
  color: #74788d;
}
</style>
