<template>
  <div>
    <div class="d-flex flex-column">
      <div class="container-listing-program">
        <p class="font-weight-bold">
          {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.LABEL_PROGRAMS") }}
        </p>

        <b-input-group class="mt-3">
          <b-input-group-prepend is-text>
            <v-icon>mdi-magnify</v-icon>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            placeholder="Search program"
            @input="searchProgramsHandler"
          ></b-form-input>
        </b-input-group>

        <b-table
          id="table-programs"
          :fields="headers"
          :items="podcasts.data"
          hover
          striped
          show-empty
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-3">Loading...</strong>
            </div>
          </template>

          <template #head(checkbox)>
            <b-form-checkbox
              v-model="checkboxAll"
              class="align-middle"
              @change="checkboxCheckedAll()"
            ></b-form-checkbox>
          </template>

          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.item.selected"
              class="align-middle"
              @change="checkboxChecked(row.item)"
            ></b-form-checkbox>
          </template>

          <template #cell(name)="row">
            {{ cutSentence(row.item.name, 150) }}
          </template>

          <template #cell(domain)="row">
            <span
              v-for="(programDomain, key) in row.item.programDomains"
              :key="key"
            >
              <span v-if="listDomains.includes(programDomain.domain.id)">{{
                programDomain.domain.name
              }}</span>
            </span>
          </template>
        </b-table>

        <div class="d-flex justify-content-between mt-3">
          <p class="float-left font-weight-bold">
            Total: {{ podcasts.total_items }}
          </p>
          <b-pagination
            v-model="podcasts.current_page"
            :total-rows="podcasts.total_items"
            :per-page="podcasts.items_per_page"
            aria-controls="table-programs"
            @change="changePage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProgramsByCompany } from "@/api/programs/getters";
import { HEADER_LISTING_PROGRAM } from "@/common/config/configuration/program";

export default {
  props: {
    programsSelected: {
      type: Array,
      required: true
    },
    companyId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      timeout: 3000,
      filterDomains: [],
      page: 1,
      limit: 10,
      checkboxAll: false,
      itemsSelected: [],
      podcasts: [],
      isLoading: false,
      headers: HEADER_LISTING_PROGRAM,
      searchProgramsFilter: ""
    };
  },

  async mounted() {
    await this.loadPrograms();
  },

  computed: {
    listPrograms() {
      let data = [];
      this.itemsSelected.forEach(program => {
        data.push(program.id);
      });

      return data;
    },
    listDomains() {
      let data = [];
      this.filterDomains.forEach(domain => {
        data.push(domain.id);
      });

      return data;
    }
  },

  methods: {
    alreadySelected() {
      this.itemsSelected.forEach(itemLine => {
        const findProgram = this.podcasts.data.find(item => {
          const id = itemLine.id;
          return id === item.id;
        });

        if (findProgram) {
          this.$set(findProgram, "selected", true);
        } else {
          console.error("cannot found ", itemLine, findProgram);
        }
        itemLine.selected = true;
      });

      if (this.itemsSelected.length === this.podcasts.data.length) {
        this.checkboxAll = true;
      }
    },

    checkboxChecked(item) {
      if (item.selected) {
        this.itemsSelected.push(item);
      } else {
        const findIndex = this.itemsSelected.findIndex(element => {
          element.id;
        });

        if (this.itemsSelected.length > 0) {
          this.itemsSelected.splice(findIndex, 1);
        }
      }
    },
    checkboxCheckedAll() {
      let items = this.podcasts.data;

      let status = this.checkboxAll;

      const rows = items.map(row => {
        return {
          ...row,
          selected: status
        };
      });

      items = rows;

      this.podcasts.data = items;
      this.itemsSelected = [...new Set([...this.itemsSelected, ...items])];
    },

    async loadPrograms() {
      this.isLoading = true;

      try {
        const filter = {
          domains: this.listDomains.join(","),
          page: this.page,
          limit: this.limit,
          search: this.searchProgramsFilter
        };

        this.podcasts = await getProgramsByCompany(
          this.companyId,
          filter,
          this.axios
        );

        if (this.programsSelected) {
          this.itemsSelected = this.programsSelected;
        }

        this.alreadySelected();
      } catch (error) {
        console.error("---ERROR-PROGRAMS---");
        console.error(error);
        this.listPrograms = [];
      }

      this.isLoading = false;
    },

    changePage(page) {
      this.page = page;
      this.checkboxAll = false;

      this.loadPrograms();
    },

    cutSentence(sentence, len) {
      let cutS = sentence.substring(0, len);
      if (40 < sentence.length && this.cutTitle) {
        cutS += "...";
      }
      return cutS;
    },

    searchProgramsHandler(text) {
      if (text && text.length > 2) {
        this.searchProgramsFilter = text.toLowerCase();

        clearTimeout(this.timeoutSearch);

        this.timeoutSearch = setTimeout(() => {
          this.isLoading = true;
          this.loadPrograms();
        }, 1000);
      }
      if (!text) {
        this.searchProgramsFilter = "";
        this.loadPrograms();
      }
      this.page = 1;
    }
  }
};
</script>

<style scoped>
.container-next-step {
  display: flex;
  justify-content: flex-end;
}
</style>
