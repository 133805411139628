import {
  get as getAPIV2,
  update as updateAPIV2,
  post as postAPIV2
} from "@/api/getApi2.0";
import { get, post, update } from "@/api/getApi1.0";
import { get as getV2, remove } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getCompanyV2 = (id, axios) =>
  getAPIV2(
    {
      path: `${ENTITY_NAME_IN_URI_V2.COMPANIES.path}/${id}`
    },
    {},
    axios
  );

export const updateCompanyV2 = (axios, id, data) =>
  updateAPIV2(
    {
      path: `${ENTITY_NAME_IN_URI_V2.COMPANIES.path}/${id}`
    },
    data,
    axios
  );

export const createCompanyV2 = (axios, data) =>
  postAPIV2(
    {
      path: `${ENTITY_NAME_IN_URI_V2.COMPANIES.path}`
    },
    {},
    data,
    axios
  );

export const getCompany = (axios, filters = null) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.COMPANIES.path
    },
    filters,
    axios
  );

export const getCompanies = (axios, filters = null) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.COMPANIES.path
    },
    filters,
    axios
  );

export const getCompaniesV2 = (axios, filters = null) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.COMPANIES.path
    },
    filters,
    axios
  );

export const createCompany = (axios, data) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.COMPANIES.path
    },
    {},
    data,
    axios
  );

export const updateCompany = (axios, data) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.COMPANIES.path
    },
    data,
    axios
  );

export const deleteCompany = (axios, id) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI_V2.COMPANIES.path,
      ressources: [
        {
          name: id
        }
      ]
    },
    {},
    axios
  );

export const getCompanydomains = axios =>
  get(
    {
      path: ENTITY_NAME_IN_URI.COMPANIES.DOMAINS.path
    },
    {},
    axios
  );
