<template>
  <div class="card-body">
    <div
      v-if="isLoading"
      class="d-flex flex-column h-100 w-100 justify-content-center align-items-center"
    >
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <span class="font-weight-bold mt-2"
        >{{ $tc("DATATABLE.LOADING_DATA") }}...</span
      >
    </div>

    <div v-if="!isLoading">
      <b-row class="mb-1">
        <b-col sm="5">
          <v-text-field
            hide-details
            label="Search by first name or last name"
            prepend-inner-icon="search"
            solo
            filled
            color="blue"
            dense
            clearable
            @input="inputSearchHandler"
          ></v-text-field>
        </b-col>
        <b-col sm="7" class="col-sm-7 text-right">
          <button
            v-if="
              isDisplayedFor([
                ROLES.BBB_ADMIN,
                ROLES.BBB_MANAGER_PUBLISHER,
                ROLES.BBB_MANAGER_ADVERTISER,
                ROLES.PUBLISHER_ADMIN,
                ROLES.ADVERTISER_ADMIN
              ])
            "
            class="btn btn-primary"
            @click="addUser"
          >
            <i class="flaticon2-plus"></i
            >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.LABEL_ADD_USER") }}
          </button>
        </b-col>
      </b-row>

      <b-table
        :items="items.data"
        :fields="headers"
        :busy="isLoadingUser"
        responsive
        striped
        hover
        bordered
        outlined
        show-empty
      >
        <template #empty>
          <div class="d-flex justify-content-center">
            <span class="font-weight-bold">{{
              $tc("DETAILS_V2.NO_USERS")
            }}</span>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>{{ $tc("DETAILS_V2.LOADING") }}</strong>
          </div>
        </template>

        <template #cell(email)="data">
          <p :title="data.item.id">{{ data.item.email }}</p>
        </template>

        <template #cell(scope)="data">
          <div v-if="data.item.domain.length !== 0" class="text-center">
            <v-icon
              medium
              v-b-tooltip.hover.html
              :title="setTooltip(data.item.domain)"
              >mdi-target
            </v-icon>
          </div>
        </template>

        <template #cell(status)="data">
          <p class="font-weight-bold">{{ data.item.status }}</p>
        </template>

        <template #cell(roles)="data">
          <p v-for="(role, key) in data.item.roles" :key="key">
            {{ rolesList[role] ?? role }}
          </p>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <v-menu v-if="data.item.email !== user.email">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <template v-for="(item, index) in dropdown">
                  <v-list-item
                    v-if="menuAccess(item, data)"
                    :disabled="menuDisabled(item, data)"
                    :key="`menu_${index}`"
                    target="_blank"
                    link
                    @click="
                      action(item.action, {
                        id: data.item.id,
                        email: data.item.email,
                        blocked: data.item.blocked
                      })
                    "
                  >
                    <v-list-item-title class="overflow-hidden">
                      <v-icon class="pr-2" :color="item.color"
                        >mdi-{{ item.icon }}
                      </v-icon>
                      {{ item.label }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
        </template>
      </b-table>

      <b-row class="my-4">
        <b-pagination
          v-bind:style="{ margin: '0 auto' }"
          align="center"
          justify="center"
          v-model="items.current_page"
          :total-rows="items.total_items"
          :per-page="items.items_per_page"
          size="sm"
          class="my-0"
          @change="changePageUser"
        ></b-pagination>
      </b-row>
    </div>

    <b-modal
      id="modal-user"
      :title="
        user_id
          ? $tc('ADVANCED_SETTINGS.INVOICING.USERS.EDIT')
          : $tc('ADVANCED_SETTINGS.INVOICING.USERS.CREATE')
      "
      header-close-content
      no-close-on-backdrop
      size="lg"
    >
      <template #modal-footer="{ close }">
        <button
          class="btn border rounded mr-3"
          :disabled="isLoading"
          @click="close"
        >
          {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
        </button>

        <button
          class="btn btn-danger mr-3"
          :disabled="isLoadingSubmit"
          @click="submit"
        >
          <template v-if="!isLoading">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.SUBMIT") }}
            <b-spinner
              v-if="isLoadingSubmit"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </template>

      <div
        v-if="isLoadingModal"
        class="d-flex flex-column h-100 w-100 justify-content-center align-items-center"
      >
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <span class="font-weight-bold mt-2"
          >{{ $tc("DATATABLE.LOADING_DATA") }}...</span
        >
      </div>

      <div v-if="!isLoadingModal">
        <fieldset class="border p-2 px-4 mb-4">
          <legend class="w-auto">
            &nbsp;{{
              $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.INFORMATION")
            }}&nbsp;
          </legend>

          <div
            v-if="hasAccess('firstName', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.FIRSTNAME")
              }}<span class="ml-1 text-danger">*</span>
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.firstName"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.INVOICING.USERS.FORM.FIRSTNAME')
                "
                :state="checkRequired(form.firstName)"
                class="form-control--grey"
                :disabled="!hasAccess('firstName', 'write')"
              />

              <b-form-invalid-feedback :state="checkRequired(form.firstName)">
                {{
                  $tc(
                    "ADVANCED_SETTINGS.INVOICING.USERS.FORM.FORM_ERROR.REQUIRED"
                  )
                }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('lastName', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.LASTNAME")
              }}<span class="ml-1 text-danger">*</span>
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.lastName"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.INVOICING.USERS.FORM.LASTNAME')
                "
                :state="checkRequired(form.lastName)"
                class="form-control--grey"
                :disabled="!hasAccess('lastName', 'write')"
              />
              <b-form-invalid-feedback :state="checkRequired(form.lastName)">
                {{
                  $tc(
                    "ADVANCED_SETTINGS.INVOICING.USERS.FORM.FORM_ERROR.REQUIRED"
                  )
                }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('email', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.EMAIL")
              }}<span class="ml-1 text-danger">*</span>
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="form.email"
                :placeholder="
                  $tc('ADVANCED_SETTINGS.INVOICING.USERS.FORM.EMAIL')
                "
                class="form-control--grey"
                :state="checkEmail"
                :disabled="!hasAccess('email', 'write')"
              />

              <b-form-invalid-feedback :state="checkEmail">
                {{
                  $tc(
                    "ADVANCED_SETTINGS.INVOICING.USERS.FORM.FORM_ERROR.WRONG_FORMAT"
                  )
                }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 px-5"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.ROLE")
              }}<span class="ml-1 text-danger">*</span>
              <p class="text-muted small">
                See more details in the
                <router-link to="/faq">FAQ</router-link>
              </p>
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-select
                v-model="form.roles"
                multiple
                :style="{ height: '120px' }"
                class="mb-3"
                :state="checkSelected(form.roles)"
              >
                <option :value="null" disabled>
                  -- Please select one or multiple roles --
                </option>
                <template v-for="(option, key) in roles">
                  <option
                    :key="key"
                    v-if="intersect(option.access, user.roles).length !== 0"
                    :value="option.value[0]"
                  >
                    {{ option.text }}
                  </option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="checkSelected(form.roles)">
                {{
                  $tc(
                    "ADVANCED_SETTINGS.INVOICING.USERS.FORM.FORM_ERROR.REQUIRED"
                  )
                }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <div
            v-if="hasAccess('admin', 'read')"
            class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.ADMIN") }}
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-checkbox
                v-model="form.admin"
                name="check-button"
                switch
                :disabled="!hasAccess('admin', 'write')"
              >
              </b-form-checkbox>
            </div>
          </div>

          <div
            v-if="hasAccess('paid', 'read')"
            class="d-flex flex-column flex-sm-row align-items-sm-center mb-3 px-5"
          >
            <label class="w-25 mr-2"
              >{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.PAID") }}
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-checkbox
                v-model="form.paid"
                name="check-button"
                switch
                :disabled="!hasAccess('paid', 'write')"
              >
              </b-form-checkbox>
            </div>
          </div>
        </fieldset>

        <fieldset class="border p-2 px-4 mb-4">
          <legend class="w-auto">
            &nbsp;{{
              $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.DOMAINS")
            }}&nbsp;
          </legend>

          <TagSelect
            ref="domain"
            :listOptions="listNetworks"
            :label="$tc('ADVANCED_SETTINGS.INVOICING.USERS.FORM.LABEL_DOMAINS')"
            buttonText="Choose domains"
            buttonIcon="mdi-domain"
            fieldName="name"
            isPaginate
            :preSelectedElement="domainsSelected"
            :isLoading="isLoadingDomain"
            :currentPage="currentPageDomains"
            :lastPage="lastPageDomains"
            @change="changeDomains"
            @changePage="changePageDomains"
          />
        </fieldset>

        <fieldset class="border p-2 px-4 mb-4">
          <legend class="w-auto">
            &nbsp;{{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.FORM.PODCASTS") }}
          </legend>

          <ListingProgram
            ref="program"
            :programsSelected="programsSelected"
            :company-id="companyId"
          />
        </fieldset>
      </div>
    </b-modal>

    <b-modal
      id="modal-user-delete"
      :title="$tc('ADVANCED_SETTINGS.INVOICING.USERS.DELETE')"
    >
      <template #modal-footer="{ close }">
        <button
          class="btn border rounded mr-3"
          :disabled="isLoading"
          @click="close"
        >
          {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
        </button>

        <button
          class="btn btn-danger mr-3"
          :disabled="isLoadingSubmit"
          @click="remove"
        >
          <template v-if="!isLoading">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CONFIRM") }}
            <b-spinner
              v-if="isLoadingSubmit"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </template>

      <p
        v-html="
          $t('ADVANCED_SETTINGS.INVOICING.USERS.DELETE_CONFIRMATION', {
            email: user_email
          })
        "
      ></p>
    </b-modal>

    <b-modal
      id="modal-user-reset"
      :title="$tc('ADVANCED_SETTINGS.INVOICING.USERS.RESET')"
    >
      <template #modal-footer="{ close }">
        <button
          class="btn border rounded mr-3"
          :disabled="isLoading"
          @click="close"
        >
          {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
        </button>

        <button
          class="btn btn-danger mr-3"
          :disabled="isLoadingSubmit"
          @click="reset"
        >
          <template v-if="!isLoading">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CONFIRM") }}
            <b-spinner
              v-if="isLoadingSubmit"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </template>

      <p
        v-html="
          $t('ADVANCED_SETTINGS.INVOICING.USERS.RESET_CONFIRMATION', {
            email: user_email
          })
        "
      ></p>
    </b-modal>

    <b-modal
      id="modal-user-blocked"
      :title="$tc('ADVANCED_SETTINGS.INVOICING.USERS.BLOCKED')"
    >
      <template #modal-footer="{ close }">
        <button
          class="btn border rounded mr-3"
          :disabled="isLoading"
          @click="close"
        >
          {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
        </button>

        <button
          class="btn btn-danger mr-3"
          :disabled="isLoadingSubmit"
          @click="blocked"
        >
          <template v-if="!isLoading">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CONFIRM") }}
            <b-spinner
              v-if="isLoadingSubmit"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </template>

      <p
        v-html="
          $t('ADVANCED_SETTINGS.INVOICING.USERS.BLOCKED_CONFIRMATION', {
            email: user_email,
            action: user_blocked ? 'unlock' : 'lock'
          })
        "
      ></p>
    </b-modal>

    <b-modal
      id="modal-user-welcome"
      :title="$tc('ADVANCED_SETTINGS.INVOICING.USERS.WELCOME')"
    >
      <template #modal-footer="{ close }">
        <button
          class="btn border rounded mr-3"
          :disabled="isLoading"
          @click="close"
        >
          {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
        </button>

        <button
          class="btn btn-danger mr-3"
          :disabled="isLoadingSubmit"
          @click="welcome"
        >
          <template v-if="!isLoading">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CONFIRM") }}
            <b-spinner
              v-if="isLoadingSubmit"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </template>

          <template v-if="isLoading">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </template>

      <p
        v-html="
          $t('ADVANCED_SETTINGS.INVOICING.USERS.WELCOME_CONFIRMATION', {
            email: user_email
          })
        "
      ></p>
    </b-modal>
  </div>
</template>

<script>
import { DROPDOWN, headers } from "@/common/config/configuration/users";
import { renderSeconds } from "@/common/functions";
import {
  createUser,
  getUser,
  getUsers,
  removeUser,
  updateUser,
  userForgotPassword,
  welcomeUser
} from "@/api/users/getters";
import {
  getAttributesRead,
  getAttributesWrite,
  hasAttributeAccess
} from "@/common/config/acl";
import auth from "@/store/auth.module";
import { FORM_USER } from "@/common/config/acl/user/abac";
import { getDomainsV2 } from "@/api/networks/getters";
import TagSelect from "@/components/TagSelectV2";
import {
  ROLES,
  READABLE_ROLES_LIST,
  getListRoles
} from "@/common/config/acl/roles";
import ListingProgram from "@/components/sponsoring/ListingProgram";

export default {
  data() {
    return {
      ROLES: ROLES,
      rolesList: READABLE_ROLES_LIST,
      user: auth.state.user,
      id: "",
      user_id: "",
      user_email: "",
      user_blocked: false,
      form: getAttributesRead(FORM_USER, auth.state.user.roles),
      companyId: "",
      items: [],
      fields: headers,
      headers: headers,
      isLoading: false,
      isLoadingUser: false,
      isLoadingModal: false,
      page: 1,
      limit: 10,
      listNetworks: [],
      isLoadingPrograms: false,
      isLoadingDomain: false,
      isLoadingSubmit: false,
      domains: [],
      domainsSelected: [],
      programsSelected: [],
      currentPageDomains: 1,
      lastPageDomains: 1,
      currentPagePrograms: 1,
      searchFilter: "",
      isCanCheck: false,
      userForm: {}
    };
  },
  components: {
    ListingProgram,
    TagSelect
  },
  async mounted() {
    this.resetData();

    if (undefined !== this.$route.params.id) {
      this.companyId = this.$route.params.id;
    }

    this.isLoading = true;
    await this.loadUsers();
    await this.loadDomains();
  },
  computed: {
    roles() {
      return getListRoles(this.user);
    },
    checkEmail() {
      const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (this.isCanCheck) {
        if (!this.form.email) {
          return false;
        }
        return regExp.test(this.form.email);
      }
      return null;
    },

    hasErrors() {
      return (
        !this.checkEmail ||
        !this.checkRequired(this.form.firstName) ||
        !this.checkRequired(this.form.lastName)
      );
    },

    dropdown() {
      return DROPDOWN;
    }
  },
  methods: {
    menuDisabled(item, data) {
      switch (item.action) {
        case "reset":
          if (data.item.status === "Pending") {
            return true;
          }
          break;
      }
      return false;
    },
    menuAccess(item, data) {
      switch (item.action) {
        case "edit":
        case "reset":
          if (data.item.email === this.user.email) {
            //car l'id provient de v1
            return true;
          }
          break;
      }
      if (!this.isDisplayedFor(item.access)) {
        return false;
      }
      if (!item.hasOwnProperty("condition")) {
        return true;
      } else {
        if (
          (item.condition === "true" && !data.item.blocked) ||
          (item.condition === "false" && data.item.blocked)
        ) {
          return true;
        }
      }
      return false;
    },
    isDisplayedFor(access = []) {
      return this.user.roles.some(item => access.includes(item));
    },

    addUser() {
      this.resetData();
      this.$bvModal.show("modal-user");
    },

    action(name, params) {
      this.user_id = params.id;
      this.user_email = params.email;
      this.user_blocked = params.blocked;

      switch (name) {
        case "edit":
          this.loadUser();
          this.$bvModal.show("modal-user");
          break;
        case "delete":
          this.$bvModal.show("modal-user-delete");
          break;
        case "reset":
          this.$bvModal.show("modal-user-reset");
          break;
        case "blocked":
        case "unblocked":
          this.$bvModal.show("modal-user-blocked");
          break;
        case "welcome":
          this.$bvModal.show("modal-user-welcome");
          break;
      }
    },

    resetData() {
      this.isCanCheck = false;

      this.id = "";
      this.user_id = "";
      this.user_email = "";
      this.user_blocked = false;

      this.form = getAttributesRead(FORM_USER, this.user.roles);
    },

    checkRequired(value) {
      return this.isCanCheck ? value.length > 1 : null;
    },
    checkSelected(value) {
      return this.isCanCheck ? value.length !== 0 : null;
    },
    hideModal(id) {
      this.$bvModal.hide(id);
    },
    inputSearchHandler(text) {
      if (text && text.length > 2) {
        this.searchFilter = text.toLowerCase();

        clearTimeout(this.timeoutSearch);

        this.timeoutSearch = setTimeout(() => {
          this.isLoadingUser = true;
          this.loadUsers();
        }, 1000);
      }
      if (!text) {
        this.searchFilter = "";
        this.loadUsers();
      }
      this.page = 1;
    },

    intersect(a, b) {
      return a.filter(Set.prototype.has, new Set(b));
    },

    hasAccess(field, accessType) {
      return hasAttributeAccess(FORM_USER, this.user.roles, field, accessType);
    },

    async loadDomains() {
      this.isLoadingDomain = true;
      try {
        const filter = {
          page: this.currentPageDomains
        };
        const response = await getDomainsV2(filter, this.axios);
        this.lastPageDomains = response.last_page;
        this.listNetworks = [...this.listNetworks.concat(response.data)];
      } catch (error) {
        console.error("---ERROR-LOAD-NETWOKRS");
        console.error(error);
        this.listNetworks = [];
      }
      this.isLoadingDomain = false;
    },

    changeDomains(domains) {
      this.domains = domains;
    },

    changePageDomains() {
      this.currentPageDomains = ++this.currentPageDomains;
      this.loadDomains();
    },

    changePageUser(page) {
      this.isLoadingUser = true;
      this.page = page;
      this.loadUsers();
    },

    setTooltip(items) {
      let tooltip = "<ul class='pl-3 mb-0 pb-0 text-left'>";
      items.forEach(item => {
        tooltip += "<li>" + item.host + "</li>";
      });
      tooltip += "</ul>";
      return tooltip;
    },

    renderSeconds(time) {
      return renderSeconds(time);
    },

    async loadUser() {
      this.isLoadingModal = true;
      try {
        const response = await getUser(this.axios, this.user_id);

        this.form = getAttributesRead(FORM_USER, this.user.roles, response);

        this.form.roles = [];
        response.roles.forEach(element => {
          this.form.roles.push(element);
        });

        this.domainsSelected = response.domain;
        this.programsSelected = response.program;
        this.changeDomains(response.domain);
      } catch (error) {
        console.error("---ERROR-LOAD-COUNTRIES");
        console.error(error);
      }
      this.isLoadingModal = false;
    },

    async loadUsers() {
      try {
        this.items = await getUsers(this.axios, {
          page: this.page,
          limit: this.limit,
          company: this.companyId,
          search: this.searchFilter
        });
      } catch (error) {
        console.error("---ERROR-LOAD-COUNTRIES");
        console.error(error);
      }
      this.isLoading = false;
      this.isLoadingUser = false;
      this.isLoadingSubmit = false;
    },

    async blocked() {
      this.isLoadingSubmit = true;
      let message = "";
      let variant = "success";

      let payload = { blocked: !this.user_blocked };

      try {
        await updateUser(this.axios, this.user_id, payload);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.MESSAGE_BLOCKED"
        );

        if (this.user_blocked) {
          message = this.$tc(
            "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.MESSAGE_UNBLOCKED"
          );
        }

        await this.loadUsers();
      } catch (error) {
        console.error("---ERROR-INVOICING---");
        console.error(error);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.ERROR.MESSAGE"
        );
        variant = "danger";
      }

      this.flashMessage(message, variant);
      this.hideModal("modal-user-blocked");
      this.isLoadingSubmit = false;
    },

    async reset() {
      this.isLoadingSubmit = true;
      let message = "";
      let variant = "success";

      try {
        await userForgotPassword(this.axios, this.user_id, {});
        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.MESSAGE_RESET"
        );
      } catch (error) {
        console.error("---ERROR-INVOICING---");
        console.error(error);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.ERROR_MESSAGE"
        );
        variant = "danger";
      }

      this.flashMessage(message, variant);
      this.hideModal("modal-user-reset");
      this.isLoadingSubmit = false;
    },

    async remove() {
      this.isLoadingSubmit = true;
      let message = "";
      let variant = "success";

      try {
        await removeUser(this.axios, this.user_id);
        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.MESSAGE_DELETE"
        );

        await this.loadUsers();
      } catch (error) {
        console.error("---ERROR-INVOICING---");
        console.error(error);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.ERROR.MESSAGE"
        );
        variant = "danger";
      }

      this.flashMessage(message, variant);
      this.hideModal("modal-user-delete");
      this.isLoadingSubmit = false;
    },

    async welcome() {
      this.isLoadingSubmit = true;
      let message = "";
      let variant = "success";

      try {
        await welcomeUser(this.axios, this.user_id);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.MESSAGE_WELCOME"
        );
      } catch (error) {
        console.error("---ERROR-WELCOME---");
        console.error(error);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.ERROR.MESSAGE"
        );
        variant = "danger";
      }

      this.flashMessage(message, variant);
      this.hideModal("modal-user-welcome");
      this.isLoadingSubmit = false;
    },

    async submit() {
      this.isCanCheck = true;
      this.isLoadingSubmit = true;
      let message = "";
      let variant = "success";

      if (!this.hasErrors) {
        try {
          const refProgram = this.$refs.program;
          const refDomain = this.$refs.domain;
          this.form.domains = refDomain.value;
          this.form.programs = refProgram.listPrograms;
          this.form.company = this.companyId;

          const payload = getAttributesWrite(
            this.form,
            FORM_USER,
            this.user.roles
          );

          if (this.user_id) {
            await updateUser(this.axios, this.user_id, payload);
            message = this.$tc(
              "ADVANCED_SETTINGS.INVOICING.TOAST.SUCCESS.MESSAGE_UPDATE"
            );
          } else {
            await createUser(this.axios, payload);
            message = this.$tc(
              "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.MESSAGE_CREATE"
            );
          }

          await this.loadUsers();
          this.hideModal("modal-user");
        } catch (error) {
          console.error("---ERROR-INVOICING---");
          console.error(error);

          message = error.response.data.message;
          variant = "danger";
        }
      } else {
        message = this.$tc("ADVANCED_SETTINGS.INVOICING.TOAST.ERROR.MESSAGE");
        variant = "danger";
      }
      this.isLoadingSubmit = false;
      this.flashMessage(message, variant);
    },

    flashMessage(message, variant = "success") {
      this.showToast({
        title: this.$tc("ADVANCED_SETTINGS.INVOICING.USERS.TOAST.TITLE"),
        message: message,
        variant: variant
      });
    },

    showToast({ title, variant, message }) {
      const h = this.$createElement;
      const id = `toaster-message`;
      const $contentToaster = h("span", message);

      this.$bvToast.toast([$contentToaster], {
        id: id,
        title: title,
        variant: variant,
        noCloseButton: false
      });
    }
  },

  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  }
};
</script>
