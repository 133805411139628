import { ROLES } from "@/common/config/acl/roles";

export const FORM_USER = {
  firstName: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  lastName: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  email: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  roles: {
    defaultValue: [],
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  admin: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN],
    visible: [ROLES.BBB_ADMIN]
  },
  paid: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN],
    write: [ROLES.BBB_ADMIN],
    visible: [ROLES.BBB_ADMIN]
  },
  domains: {
    defaultValue: [],
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  programs: {
    defaultValue: [],
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  company: {
    defaultValue: [],
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  }
};
