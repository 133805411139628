import { ROLES } from "@/common/config/acl/roles";
import i18n from "@/common/plugins/vue-i18n";

export const HEADER_LISTING_PROGRAM = [
  {
    label: "",
    key: "checkbox",
    thStyle: { width: "5%" }
  },
  {
    label: "Program",
    key: "name"
  }
];

export const LINKS_FEED = [
  {
    icon: "checkbox-blank-circle",
    color: "success",
    label: "Set online",
    action: "online",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  },
  {
    icon: "checkbox-blank-circle",
    color: "error",
    label: "Set offline",
    action: "offline",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  },
  {
    icon: "checkbox-blank-circle",
    color: "success",
    label: "Import enabled",
    action: "importenabled",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  {
    icon: "checkbox-blank-circle",
    color: "error",
    label: "Import disabled",
    action: "importdisabled",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  {
    icon: "pencil",
    action: "edit",
    color: "info",
    label: "Show details",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  },
  {
    icon: "format-list-bulleted",
    action: "episodes",
    label: i18n.t("ADVANCED_SETTINGS.CONTENT.EPISODES_LIST"),
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  },
  {
    icon: "podcast",
    color: "warning",
    action: "broadcast",
    label: "Broadcast",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  },
  {
    icon: "opacity",
    color: "error",
    action: "cleanimages",
    label: "Clean images",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  {
    icon: "delete",
    color: "error",
    action: "delete",
    label: "Delete",
    access: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_USER
    ]
  }
];
