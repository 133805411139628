<template>
  <div class="dropdown form-control--grey" :title="selectedItems">
    <button
      class="btn btn-secondary btn-block dropdown-toggle"
      :class="`${disabled ? 'not-allowed' : ''}`"
      type="button"
      data-toggle="dropdown"
      :disabled="disabled"
    >
      {{
        selectedItems.length === 0
          ? label
          : selectedItems.length + " option(s) selected"
      }}
    </button>
    <div class="dropdown-menu">
      <button
        v-for="(option, index) in options"
        :key="`option_${index}`"
        class="dropdown-item m-0 p-0"
        type="button"
        style="display: flex; align-items: center"
      >
        <label class="py-3 pr-12" @click.stop="">
          <input
            v-model="selectedItems"
            type="checkbox"
            class="ml-3 mr-2"
            :value="option"
            @change="selectOption"
          />{{ option }}</label
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectMultiple",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      selectedItems: []
    };
  },

  watch: {
    items: {
      handler: function(val) {
        this.selectedItems = [...val];
      },
      immediate: true
    }
  },

  methods: {
    selectOption() {
      this.$emit("change");
    }
  }
};
</script>

<style>
.not-allowed {
  cursor: not-allowed !important;
}
</style>
